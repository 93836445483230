.c-container {
  position: relative;
  min-height: 100%;
}
main {
  padding-bottom: 100px;
  height: calc(100vh-550px);
}
header,
footer {
  line-height: 100px;
  height: 100px;
}
footer {
  width: 100%;
  position: absolute;
  bottom: 0;
}
