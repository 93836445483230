body {
  height: 100%;
  display: flex;
  display: grid;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.grid-container {
  display: grid;
}

#root{
  display: flex;
  flex-direction: column;
  height: 100vh;
}
