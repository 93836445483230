.leftpanel{
    background-color: aqua;
}

.middlepanel{
    background-color: blue;
}

.rightpanel{

}