.mychat {
  position: relative;
  display: grid;
  width: 100%;
  flex: 1;
  grid-template-columns: 30% 70%;
  background-color: #2196f3;

  overflow: auto;

  grid-template-areas: "sidebar content";
}
.rightpanel {
  display: flex;
  grid-area: "content";
  background-color: rgb(85, 59, 28);
  width: 100%;
  height: 100%;
  overflow: auto;
  grid-template-rows: 1fr auto;
}
.mychannel {
  grid-area: "sidebar";
  background-color: bisque;
  height: 100%;
  overflow: auto;
}
.mymsglist {
  /*display: inline-flex;*/
  /* overflow-y: scroll; */
  background-color: blueviolet;
  flex: 1;
  height: 100%;
  overflow: auto;
}

.myinput {
  position: absolute;
  bottom: 0;
  width: 70%;
  background-color: aqua;
}
